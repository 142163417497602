<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 09:52:37
 * @LastEditTime: 2024-11-15 17:47:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\patientList.vue
-->
<template>
  <div class="patientlist_page">
    <van-sticky offset-top="0">
      <div class="page_bottom">
        <div class="bottom_add" @click="getPatientAddAction">
          <img class="add-image" src="../../../assets/img/add_hollow.png">
          添加就诊人
        </div>
      </div>
    </van-sticky>
    <div class="page_main">
      <div v-for="(item,index) in patientList" :key="index" class="main_item">
        <div class="item_info" @click="getSetDefaultPatientData(item)">
          <img class="info_img" src="https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_user_info.png" alt="">
          <div class="info_name">
            <div class="name_xm">
              <a v-if="item.sfmr=='1'" class="xm_sfmr">默认</a>
              {{ item.xm }} 
              <a class="xm_sjh">{{ encryptPhoneNum(item.sjh) }}</a>
            </div>
            <div class="name_zjh">
              {{ encryptIdCordNum(item.zjh) }}
            </div>
          </div>
          <div class="item_delete">
            <van-icon class="delete_edit" name="edit" size="23" color="#1e8dff" @click.stop="editPatientAction(item)" />
            <!-- <van-icon name="delete" size="23" color="#1e8dff" @click.stop="deletePatientAction(item)" /> -->
            <img class="img" :src="require('../../../assets/img/shanchu.png')" @click.stop="deletePatientAction(item)">
          </div>
        </div>
        <div v-if="item.patientCard && item.patientCard.length>0 " class="item_card" @click="getpatientCardDetail(item)">
          <div class="card-left">
            <div class="card_xm">
              {{ item.patientCard[0].jzklxName }}
            </div>
            <div class="card_xm ml-12">
              {{ item.patientCard[0].jzkh }}
            </div>
            <a class="xm_sfmr">默认</a>
          </div>
          <div class="card-right">
            <div>
              {{ item.patientCard.length }}张卡 
            </div>
            <van-icon name="arrow" size="20" color="#9a9a9a" />
          </div>
        </div>
      </div>
      <div v-if="patientList.length==0">
        <van-empty description="暂无就诊人" />
      </div> 
    </div>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'
import { encryptPhoneNum, encryptIdCordNum} from '@/utils'

export default {
    data(){
        return{
            page:1, //页码
            patientList:[] //就诊人列表
        }
    },
    mounted() {
        this.getPatientListData()
    },
    methods:{
        getPatientListData(){
            const params = {
                yydm: '',
                page: this.page,
                pageSize:'10'
            }
            gjcModel.getPatientListData(params).then(res=>{
                console.log('获取就诊人列表', res)
                this.patientList = res.data

            })
        },
        //手机号码脱敏
        encryptPhoneNum(num) {
            return num ? encryptPhoneNum(num) : ''
        },
        //身份证号码脱敏
        encryptIdCordNum(num) {
            return num ? encryptIdCordNum(num) : ''
        },
        //删除就诊人
        deletePatientAction(item){
            // console.log('删除就诊人', item)
            this.$dialog.alert({
                title: '删除提示',
                message: '确认删除就诊人[ '+item.xm+' ]吗?',
                showCancelButton: true
            }).then(() => {
                this.getDeletePatientData(item.id)
            }).catch(() => {
                this.$dialog.close()
            })
        },
        //编辑就诊人
        editPatientAction(item){
            console.log('编辑就诊人', item)
            this.$router.push({
                path: '/patientEdit',
                query: {
                    id: item.id
                }
            })
        },
        //删除就诊人
        getDeletePatientData(id) {
            gjcModel.getDeletePatientData(id).then(res=>{
                console.log('删除就诊人', res)
                this.$toast('删除成功')
                this.getPatientListData()
            })
        },
        //设置默认就诊人
        getSetDefaultPatientData(item) {
            gjcModel.getSetDefaultPatientData(item.id).then(res=>{
                console.log('设置默认就诊人', res)
                this.$router.back()
            })
        },
        //添加就诊人页面
        getPatientAddAction(){
            this.$router.push({
                path: '/patientAdd'
            })
        },
        getpatientCardDetail(item){
            this.$router.push({
                path: '/cardList',
                query:{
                    patientId: item.id
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.patientlist_page{
    background-color: #fff;
    height: 100vh;
}
.page_main{
    background-color: white;
    height: 88vh;
    overflow: auto;
    .main_item{
        margin: 24px 32px;
        background: rgba(30,141,255,0.1);
        border-radius: 16px;
        padding: 32px 24px;
        .item_delete{
            position: relative;
            top: 25px;
            left: 20px;
            width: 120px;
            height: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .delete_edit{
                margin-right: 20px;
            }
        }
        .item_info{
            display: flex;
            justify-content: space-between;
            .info_img{
                width: 100px;
                height: 100px;
            }
            .info_name{
                margin-left: 24px;
                flex: 1;
                .name_xm{
                    display: flex;
                    align-items: center;
                    font-size: 28px;
                    color: #222B31;
                    line-height: 42px;
                    font-weight: bold;
                    .xm_sfmr{
                        margin-right: 16px;
                        padding: 2px 8px;
                        font-size: 26px;
                        color: #3AC39E;
                        line-height: 30px;
                        background: #E0F5EF;
                        border-radius: 4px;
                        border: 1px solid #3AC39E;
                    }
                    .xm_sjh{
                        margin-left: 16px;
                    }
                }
                .name_zjh{
                    margin-top: 12px;
                    font-size: 26px;
                    color: #6A6D78;
                    letter-spacing: 1px;
                }
            }
        }
        .item_card{
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            padding: 20px 24px;
            background-color:#fff;
            border-radius: 12px;
            .xm_sfmr{
                margin-left: 12px;
                border-radius: 4px;
                padding: 2px 8px;
                font-size: 22px;
                background: #eee;
                color: #9a9a9a;
                font-weight: normal;
            }
            .card-left{
                display: flex;
                align-items: center;
                .card_xm{
                    font-size: 30px;
                    color: #3C4455;
                    line-height: 42px;
                }
            }
            .card-right{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: #9A9A9A;
                line-height: 42px;
            }
            
        }
    }
}
.img {
        display: block;
        margin: 0 auto;
        width: 35px;
        height: 35px;
      }
.page_bottom{
    width: 100%;
    background-color: white;
    .bottom_add{
        margin: 20px 32px 0;
        padding: 20px 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #1E8DFF;
        line-height: 45px;
        letter-spacing: 1px;
        font-weight: 600;
        border:2px solid #1e8dff;
        .add-image{
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
    }
}

.ml-12{
    margin-left: 12px;
}

</style>
